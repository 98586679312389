<template>
  <div>
    <v-card
      flat
      class="px-3 mx-sm-5"
      v-if="brand"
    >
      <v-alert
        dismissible
        text
        outlined
        type="error"
        v-if="featuresErrorMessage"
        class="mt-4 col-12 text-center"
      >
        {{ featuresErrorMessage }}
      </v-alert>
      <v-alert
        dismissible
        text
        outlined
        type="error"
        v-if="brandErrorMessage"
        class="mt-4 col-12 text-center"
      >
        {{ brandErrorMessage }}
      </v-alert>
      <v-card-title>{{ $t('Features') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-form ref="brandFeatures">
              <v-text-field
                ref
                outlined
                :label="$t('warehousePartQuota')"
                v-model="brand.features.warehousePartQuota"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-form ref="brandFeatures">
              <v-text-field
                ref
                outlined
                type="number"
                :label="$t('userQuota')"
                v-model="brand.features.userQuota"
              >
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-form ref="accessToBCMNetwork">
              <v-switch
                v-model="brand.features.accessToBCMNetwork"
                :label="$t('AccessToBCMSuppliersNetwork')"
                inset
                color="success"
              >
              </v-switch>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-form ref="enableTags">
              <v-switch
                v-model="brand.features.enableTags"
                :label="$t('enableTags')"
                inset
                color="success"
              >
              </v-switch>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-form ref="enableSerialNumber">
              <v-switch
                v-model="brand.features.enableSerialNumber"
                :label="$t('EnableSerialNumber')"
                inset
                color="success"
              >
              </v-switch>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-form ref="forcePurchaseOrder">
              <v-switch
                v-model="brand.features.forcePurchaseOrder"
                :label="$t('forcePurchaseOrder')"
                inset
                color="success"
              >
              </v-switch>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="brand.features.availableDeliveryCountries"
              :items="allCountries"
              outlined
              chips
              attach
              item-text="name"
              item-value="code"
              small-chips
              :label="$t('AvailableDeliveryCountries')"
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    brand.features.availableDeliveryCountries.length ===
                      allWorldCountries.length
                      ? (brand.features.availableDeliveryCountries = [])
                      : (brand.features.availableDeliveryCountries = allWorldCountries)
                  "
                >
                  <v-list-item-action>
                    <v-icon
                      :color="
                        brand.features.availableDeliveryCountries.length ===
                          allWorldCountries.length
                          ? 'success'
                          : ''
                      "
                    >
                      fas fa-globe
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="
                        brand.features.availableDeliveryCountries.length ===
                          allCountries.length
                          ? 'success--text'
                          : ''
                      "
                    >
                      {{ $t('AllCountriesInTheWorld') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  ripple
                  @click="
                    brand.features.availableDeliveryCountries.length ===
                      allEuropeCountries.length
                      ? (brand.features.availableDeliveryCountries = [])
                      : (brand.features.availableDeliveryCountries = allEuropeCountries)
                  "
                >
                  <v-list-item-action>
                    <v-icon
                      :color="
                        brand.features.availableDeliveryCountries.length ===
                          allEuropeCountries.length
                          ? 'success'
                          : ''
                      "
                    >
                      fas fa-globe-europe
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="
                        brand.features.availableDeliveryCountries.length ===
                          allEuropeCountries.length
                          ? 'success--text'
                          : ''
                      "
                    >
                      {{ $t('AllCountriesInEurope') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              :items="paymentMethods"
              :label="$t('DefaultPaymentMethod')"
              v-model="paymentMethod"
              outlined
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-form ref="brandFeatures">
              <v-text-field
                ref
                outlined
                min="1"
                step=".01"
                type="number"
                append-icon="fas fa-percentage"
                :label="$t('defaultMargin')"
                v-model.number.lazy="brand.features.defaultMargin"
              >
              </v-text-field>
            </v-form>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              :items="quoteCalculations"
              :label="$t('QuoteCalculation')"
              v-model="brand.features.quoteCalculation"
              outlined
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              outlined
              min="0"
              step="0.01"
              type="number"
              append-icon="fas fa-euro-sign"
              :label="$t('deliveryDiscount')"
              v-model.number.lazy="brand.features.deliveryDiscount"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-autocomplete
              v-model="brand.features.theme"
              :items="themes"
              outlined
              attach
              item-text="_id"
              item-value="_id"
              :label="$t('theme')"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card v-if="brand" class="my-5">
          <v-card-title>{{ $t('startupFeature') }}</v-card-title>
          <v-card-text
            v-if="brand.subscription.plan !== 'STARTUP'"
          >
            {{ $t('startupFeatureNotAvailable') }}
          </v-card-text>
          <v-card-text v-else class="">
            <v-row>
              <v-col>
                <p>{{ $t('startupFeatureDescription1') }}</p>
                <p>{{ $t('startupFeatureDescription2') }}</p>
                <p>{{ $t('startupFeatureDescription3') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="startupFeatureEnabled"
                  :label="$t('startupFeatureEnabled')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  :disabled="!startupFeatureEnabled"
                  v-model="brand.features.startup.isManager"
                  :label="$t('startupFeatureIsManager')"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-form ref="brandFeatures" class="col-11">
                  <v-autocomplete
                    :disabled="
                      !startupFeatureEnabled || brand.features.startup.isManager
                    "
                    ref
                    v-model="brand.features.startup.managedBy"
                    :items="brands"
                    item-text="name"
                    item-value="_id"
                    outlined
                    :label="$t('startupFeatureManagedBy')"
                  ></v-autocomplete>
                </v-form>
              </v-col>
            </v-row>
            <v-divider v-if="brandManagedStartups.length > 0"></v-divider>
            <v-row v-if="brandManagedStartups.length > 0" class="mt-2">
              <v-col>
                <p class="title">
                  {{ $t('ManagedStartups') }}
                </p>
                <v-data-table
                  :headers="headersManagedStartups"
                  :items="brandManagedStartups"
                  sort-by="created"
                  :sort-desc="true"
                  :footer-props="{
                    itemsPerPageOptions: [15, 50, 100, -1]
                  }"
                  @click:row="goToPageItem($event)"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <router-link
                      :to="{name: 'BrandGeneral', params: { brandUUID: `${item._id}`}}"
                      class="text-decoration-none"
                    >
                      {{ item.name }}
                    </router-link>
                  </template>
                  <template v-slot:[`item.created`]="{ item }">
                    <router-link
                      :to="{name: 'BrandGeneral', params: { brandUUID: `${item._id}`}}"
                      class="text-decoration-none"
                    >
                      {{ $d(new Date(item.created), "long") }}
                    </router-link>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <router-link
                      :to="{name: 'BrandGeneral', params: { brandUUID: `${item._id}`}}"
                      class="text-decoration-none"
                    >
                      <v-btn
                        color="primary"
                        :to="{name: 'BrandGeneral', params: { brandUUID: `${item._id}`}}"
                        class="white--text"
                        :title="$t('view')"
                      >
                        <v-icon small>
                          fas fa-eye
                        </v-icon>
                      </v-btn>
                    </router-link>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="brand" class="my-5">
          <v-card-title>{{ $t('sharingFeature') }}</v-card-title>
          <v-card-text class="mx-3">
            <v-row>
              <p>{{ $t('sharingFeatureDescription1') }}</p>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="brand.features.sharing.enabled"
                :label="$t('sharingFeatureEnabled')"
              >
              </v-checkbox>
            </v-row>
            <v-row>
              <v-form ref="sharingFeatures" class="col-11">
                <v-autocomplete
                  v-model="brand.features.sharing.sharingWith"
                  :items="brands"
                  item-text="name"
                  item-value="_id"
                  multiple
                  outlined
                  chips
                  :label="$t('canShareWith')"
                  :disabled="!brand.features.sharing.enabled"
                ></v-autocomplete>
              </v-form>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="brand" class="my-5">
          <v-card-title>{{ $t('PrivateSuppliersList') }}</v-card-title>
          <v-card-text class="">
            <v-row>
              <v-col>
                <p>{{ $t('PrivateSuppliersListDescription1') }}</p>
                <p>{{ $t('PrivateSuppliersListDescription2') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-form ref="privateSuppliersFeatureRef">
                  <v-autocomplete
                    v-model="selectedPrivateSuppliers"
                    :items="suppliers"
                    item-text="name"
                    item-value="_id"
                    multiple
                    outlined
                    chips
                    :label="$t('PrivateSuppliers')"
                    :menu-props="{bottom: true}"
                    clearable
                    auto
                    @input="selectedPrivateSuppliersChanged"
                  >
                  </v-autocomplete>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headersPrivateSuppliers"
                  :items="brand.features.privateSuppliers"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span>
                      {{ suppliersMap[item.supplier]?.name }}
                    </span>
                  </template>
                  <template v-slot:[`item.billing`]="{ item }">
                    <v-simple-checkbox
                      :ripple="false"
                      v-model="item.billing"
                    ></v-simple-checkbox>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
            color="error"
            class="text-none mr-2"
            @click="getBrand"
          >
            <v-icon small class="mr-1">
              fa fa-ban
            </v-icon> {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="text-none"
            @click="
              brand.features.userQuota >= brandRoles.length
                ? modifyBrandFeatures()
                : (usersSortingDialog = true)
            "
          >
            <v-icon small class="mr-1">
              fa fa-check
            </v-icon> {{ $t('Submit') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
    <v-dialog
      v-model="usersSortingDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-alert type="error">
            {{ $t('usersSortingDialogTitle') }}
          </v-alert>
          <p v-html="$t('usersSortingDialogText')"></p>
          <v-checkbox
            v-for="user in brandRoles"
            v-model="usersToDelete"
            :label="`${user.email}
          ${
              user.firstname !== ' ' || user.lastname !== ' '
                ? `(${user.firstname} ${user.lastname})`
                : ''
            }`"
            :value="user.email"
            :key="user.email"
            color="info"
          >
          </v-checkbox>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="
              usersToDelete = []
              getBrand()
              usersSortingDialog = false
            "
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            v-if="
              usersToDelete.length > 0 &&
                usersToDelete.length >=
                brandRoles.length - brand.features.userQuota
            "
            color="error"
            @click="
              deleteBrandUsers()
              usersSortingDialog = false
            "
          >
            delete {{ usersToDelete.length }} users
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import countryList from 'country-list';

const i18nData = require('./pageBrandFeatures.i18n.json');

export default {
  name: 'PageBrandFeatures',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      allCountries: [],
      allEuropeCountries: [
        'DE',
        'AT',
        'BE',
        'BG',
        'CY',
        'HR',
        'DK',
        'ES',
        'EE',
        'FI',
        'FR',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'MT',
        'LU',
        'NL',
        'PL',
        'PT',
        'CZ',
        'RO',
        'SK',
        'SI',
        'SE',
        'CH',
        'LI',
        'AD',
        'MC',
        'VA',
        'SM',
      ],
      allWorldCountries: countryList.getCodes(),
      brands: [],
      brand: null,
      brandRolesData: null,
      brandRoles: [],
      brandManagedStartups: [],
      headersManagedStartups: [],
      usersSortingDialog: false,
      usersToDelete: [],
      featuresErrorMessage: null,
      brandErrorMessage: null,
      paymentMethod: null,
      paymentMethods: [
        {text: this.$t('CARD'), value: 'CARD'},
        {text: this.$t('TRANSFER'), value: 'TRANSFER'},
        {text: this.$t('MANUAL'), value: 'MANUAL'},
        {text: this.$t('SEPA'), value: 'SEPA'},
        {text: this.$t('AUTO'), value: 'AUTO'},
      ],
      /**
       * Quote calculation mode
       */
      quoteCalculations: [
        {text: this.$t('REAL_PRICE'), value: 'REAL_PRICE'},
        {text: this.$t('GLOBAL_DISCOUNT'), value: 'GLOBAL_DISCOUNT'},
      ],
      /**
       * Features => Startup Features Action
       */
      startupFeatureEnabled: false,
      /**
       * Private suppliers
       */
      suppliers: [],
      suppliersMap: {},
      headersPrivateSuppliers: [],
      selectedPrivateSuppliers: [],
      themes: [],
    };
  },
  created() {
    this.headersManagedStartups = [
      { text: this.$t('Name'), value: 'name' },
      { text: this.$t('Created'), value: 'created' },
      { text: this.$t('Action'), value: 'action', align: 'center' },
    ];
    this.headersPrivateSuppliers = [
      { text: this.$t('Supplier'), value: 'name' },
      { text: this.$t('Billing'), value: 'billing' },
    ];
    /**
     * GET BRAND
     */
    this.allCountries = countryList.getData();
    this.getBrand();
    this.getAllBrands();
    this.getAllSuppliers();
    this.getAllThemes();
  },
  methods: {
    /**
     * GET BRAND
     */
    getAllBrands() {
      this.$apiInstance
        .getAllBrands()
        .then(
          (data) => {
            this.brands = data;
            for(const brand of data) {
              if(brand.features.startup && brand.features.startup.managedBy === this.$route.params.brandUUID) {
                this.brandManagedStartups.push(brand);
              }

            }
          },
          (error) => {
            /**
             * ERROR GET BRAND
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getAllSuppliers() {
      this.$apiInstance.getAllSuppliers().then(data => {
        this.suppliers = data;
        for(const supplier of this.suppliers) {
          this.suppliersMap[supplier._id] = supplier;
        }
      });
    },
    /**
     * GET BRAND
     */
    getBrand() {
      this.loading = true;
      this.$apiInstance
        .getAdminBrand(this.$route.params.brandUUID)
        .then(
          (brandData) => {
            this.selectedPrivateSuppliers = [];
            if (!brandData.features.startup || brandData.features.startup === null) {
              brandData.features.startup = {
                isManager: false,
                managedBy: '',
              };
            } else if (
              brandData.features.startup.isManager ||
              brandData.features.startup.managedBy !== ''
            ) {
              this.startupFeatureEnabled = true;
            }
            this.brand = brandData;
            this.paymentMethod = this.brand.features.defaultPaymentMethod.toUpperCase();
            for(const privateSupplierInfo of this.brand.features.privateSuppliers) {
              this.selectedPrivateSuppliers.push(privateSupplierInfo.supplier);
            }
          },
          (error) => {
            /**
             * ERROR GET BRAND
             */
            this.brandErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * GET BRAND ROLES
     */
    getBrandRoles() {
      this.loadingTableRoles = true;
      this.$apiInstance
        .getBrandRoles(this.$route.params.brandUUID)
        .then(
          (brandRolesData) => {
            this.brandRolesData = brandRolesData;
            this.parseBrandRoles(this.brandRolesData);
          },
          (error) => {
            /**
             * ERROR GET BRAND ROLES
             */
            this.brandRolesErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTableRoles = false;
        });
    },
    /**
     * Get all themes
     */
    getAllThemes() {
      this.$apiInstance.getThemes()
        .then(
          (data) => {
            this.themes = data;
          },
          (error) => {
            /**
             * ERROR GET OFFERS
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * PARSING BRAND ROLES
     */
    parseBrandRoles(brandRolesData) {
      const brandRolesDataCopy = JSON.parse(JSON.stringify(brandRolesData));
      const brandRolesArray = [];
      for (const roleUserMapping of brandRolesDataCopy.access) {
        if (!(roleUserMapping._id in brandRolesArray)) {
          brandRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        brandRolesArray[roleUserMapping._id].access = true;
      }
      for (const roleUserMapping of brandRolesDataCopy.admin) {
        if (!(roleUserMapping._id in brandRolesArray)) {
          brandRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        brandRolesArray[roleUserMapping._id].admin = true;
      }
      const brandRoles = [];
      for (const userId in brandRolesArray) {
        if (!brandRolesArray[userId].access) {
          brandRolesArray[userId].access = false;
        }
        if (!brandRolesArray[userId].admin) {
          brandRolesArray[userId].admin = false;
        }
        brandRoles.push(brandRolesArray[userId]);
      }
      this.brandRoles = brandRoles;
    },
    selectedPrivateSuppliersChanged() {
      /**
       * Remove unselected suppliers
       */
      this.brand.features.privateSuppliers = this.brand.features.privateSuppliers.filter(psInfo => {
        return this.selectedPrivateSuppliers.includes(psInfo.supplier);
      });

      const currentPrivateSuppliers = new Set(this.brand.features.privateSuppliers.map(psInfo => {return psInfo.supplier;}));

      /**
       * Add freshly added suppliers
       */
      this.brand.features.privateSuppliers = [...this.brand.features.privateSuppliers, ...this.selectedPrivateSuppliers.filter(sID => {
        return !currentPrivateSuppliers.has(sID);
      }).map(sID => {
        return {
          supplier: sID,
          billing: false
        };
      })];
    },
    /**
     * MODIFY BRAND FEATURES
     */
    modifyBrandFeatures() {
      if (this.$refs.brandFeatures.validate()) {
        const brandFeatures = new this.$BcmModel.AdminBrandFeatures(
          parseInt(this.brand.features.warehousePartQuota),
          this.brand.features.availableDeliveryCountries,
          parseInt(this.brand.features.userQuota),
          this.brand.features.enableTags,
          this.brand.features.forcePurchaseOrder,
          this.paymentMethod.toLowerCase(),
          this.brand.features.defaultMargin,
          this.brand.features.enableSerialNumber,
          this.brand.features.privateSuppliers,
          this.brand.features.quoteCalculation,
          this.brand.features.deliveryDiscount,
          this.brand.features.accessToBCMNetwork,
          this.brand.features.theme,
        );
        if (
          this.brand.subscription.plan === 'STARTUP' &&
          this.startupFeatureEnabled
        ) {
          brandFeatures.startup = this.brand.features.startup;
        }
        brandFeatures.sharing = this.brand.features.sharing;
        this.$apiInstance
          .modifyBrandFeatures(this.$route.params.brandUUID, brandFeatures)
          .then(
            () => {
              this.getBrand();
              this.$notification.notify('SUCCESS',this.$t('BrandFeaturesSuccessfullyModified'));
            },
            (error) => {
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    goToPageItem(item) {
      this.$router.push({name: 'BrandGeneral', params: { brandUUID: `${item._id}`}});
    },
  },
};
</script>
