var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.brand ? _c('v-card', {
    staticClass: "px-3 mx-sm-5",
    attrs: {
      "flat": ""
    }
  }, [_vm.featuresErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.featuresErrorMessage) + " ")]) : _vm._e(), _vm.brandErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.brandErrorMessage) + " ")]) : _vm._e(), _c('v-card-title', [_vm._v(_vm._s(_vm.$t('Features')))]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-form', {
    ref: "brandFeatures"
  }, [_c('v-text-field', {
    ref: "",
    attrs: {
      "outlined": "",
      "label": _vm.$t('warehousePartQuota')
    },
    model: {
      value: _vm.brand.features.warehousePartQuota,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "warehousePartQuota", $$v);
      },
      expression: "brand.features.warehousePartQuota"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-form', {
    ref: "brandFeatures"
  }, [_c('v-text-field', {
    ref: "",
    attrs: {
      "outlined": "",
      "type": "number",
      "label": _vm.$t('userQuota')
    },
    model: {
      value: _vm.brand.features.userQuota,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "userQuota", $$v);
      },
      expression: "brand.features.userQuota"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "4"
    }
  }, [_c('v-form', {
    ref: "accessToBCMNetwork"
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.$t('AccessToBCMSuppliersNetwork'),
      "inset": "",
      "color": "success"
    },
    model: {
      value: _vm.brand.features.accessToBCMNetwork,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "accessToBCMNetwork", $$v);
      },
      expression: "brand.features.accessToBCMNetwork"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-form', {
    ref: "enableTags"
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.$t('enableTags'),
      "inset": "",
      "color": "success"
    },
    model: {
      value: _vm.brand.features.enableTags,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "enableTags", $$v);
      },
      expression: "brand.features.enableTags"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-form', {
    ref: "enableSerialNumber"
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.$t('EnableSerialNumber'),
      "inset": "",
      "color": "success"
    },
    model: {
      value: _vm.brand.features.enableSerialNumber,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "enableSerialNumber", $$v);
      },
      expression: "brand.features.enableSerialNumber"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-form', {
    ref: "forcePurchaseOrder"
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.$t('forcePurchaseOrder'),
      "inset": "",
      "color": "success"
    },
    model: {
      value: _vm.brand.features.forcePurchaseOrder,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "forcePurchaseOrder", $$v);
      },
      expression: "brand.features.forcePurchaseOrder"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.allCountries,
      "outlined": "",
      "chips": "",
      "attach": "",
      "item-text": "name",
      "item-value": "code",
      "small-chips": "",
      "label": _vm.$t('AvailableDeliveryCountries'),
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function () {
        return [_c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": function ($event) {
              _vm.brand.features.availableDeliveryCountries.length === _vm.allWorldCountries.length ? _vm.brand.features.availableDeliveryCountries = [] : _vm.brand.features.availableDeliveryCountries = _vm.allWorldCountries;
            }
          }
        }, [_c('v-list-item-action', [_c('v-icon', {
          attrs: {
            "color": _vm.brand.features.availableDeliveryCountries.length === _vm.allWorldCountries.length ? 'success' : ''
          }
        }, [_vm._v(" fas fa-globe ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          class: _vm.brand.features.availableDeliveryCountries.length === _vm.allCountries.length ? 'success--text' : ''
        }, [_vm._v(" " + _vm._s(_vm.$t('AllCountriesInTheWorld')) + " ")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": function ($event) {
              _vm.brand.features.availableDeliveryCountries.length === _vm.allEuropeCountries.length ? _vm.brand.features.availableDeliveryCountries = [] : _vm.brand.features.availableDeliveryCountries = _vm.allEuropeCountries;
            }
          }
        }, [_c('v-list-item-action', [_c('v-icon', {
          attrs: {
            "color": _vm.brand.features.availableDeliveryCountries.length === _vm.allEuropeCountries.length ? 'success' : ''
          }
        }, [_vm._v(" fas fa-globe-europe ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          class: _vm.brand.features.availableDeliveryCountries.length === _vm.allEuropeCountries.length ? 'success--text' : ''
        }, [_vm._v(" " + _vm._s(_vm.$t('AllCountriesInEurope')) + " ")])], 1)], 1), _c('v-divider', {
          staticClass: "mt-2"
        })];
      },
      proxy: true
    }], null, false, 2002225638),
    model: {
      value: _vm.brand.features.availableDeliveryCountries,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "availableDeliveryCountries", $$v);
      },
      expression: "brand.features.availableDeliveryCountries"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.paymentMethods,
      "label": _vm.$t('DefaultPaymentMethod'),
      "outlined": ""
    },
    model: {
      value: _vm.paymentMethod,
      callback: function ($$v) {
        _vm.paymentMethod = $$v;
      },
      expression: "paymentMethod"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-form', {
    ref: "brandFeatures"
  }, [_c('v-text-field', {
    ref: "",
    attrs: {
      "outlined": "",
      "min": "1",
      "step": ".01",
      "type": "number",
      "append-icon": "fas fa-percentage",
      "label": _vm.$t('defaultMargin')
    },
    model: {
      value: _vm.brand.features.defaultMargin,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "defaultMargin", _vm._n($$v));
      },
      expression: "brand.features.defaultMargin"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.quoteCalculations,
      "label": _vm.$t('QuoteCalculation'),
      "outlined": ""
    },
    model: {
      value: _vm.brand.features.quoteCalculation,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "quoteCalculation", $$v);
      },
      expression: "brand.features.quoteCalculation"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "min": "0",
      "step": "0.01",
      "type": "number",
      "append-icon": "fas fa-euro-sign",
      "label": _vm.$t('deliveryDiscount')
    },
    model: {
      value: _vm.brand.features.deliveryDiscount,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "deliveryDiscount", _vm._n($$v));
      },
      expression: "brand.features.deliveryDiscount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.themes,
      "outlined": "",
      "attach": "",
      "item-text": "_id",
      "item-value": "_id",
      "label": _vm.$t('theme')
    },
    model: {
      value: _vm.brand.features.theme,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features, "theme", $$v);
      },
      expression: "brand.features.theme"
    }
  })], 1)], 1), _vm.brand ? _c('v-card', {
    staticClass: "my-5"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('startupFeature')))]), _vm.brand.subscription.plan !== 'STARTUP' ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t('startupFeatureNotAvailable')) + " ")]) : _c('v-card-text', {}, [_c('v-row', [_c('v-col', [_c('p', [_vm._v(_vm._s(_vm.$t('startupFeatureDescription1')))]), _c('p', [_vm._v(_vm._s(_vm.$t('startupFeatureDescription2')))]), _c('p', [_vm._v(_vm._s(_vm.$t('startupFeatureDescription3')))])])], 1), _c('v-row', [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('startupFeatureEnabled')
    },
    model: {
      value: _vm.startupFeatureEnabled,
      callback: function ($$v) {
        _vm.startupFeatureEnabled = $$v;
      },
      expression: "startupFeatureEnabled"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "disabled": !_vm.startupFeatureEnabled,
      "label": _vm.$t('startupFeatureIsManager')
    },
    model: {
      value: _vm.brand.features.startup.isManager,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features.startup, "isManager", $$v);
      },
      expression: "brand.features.startup.isManager"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-form', {
    ref: "brandFeatures",
    staticClass: "col-11"
  }, [_c('v-autocomplete', {
    ref: "",
    attrs: {
      "disabled": !_vm.startupFeatureEnabled || _vm.brand.features.startup.isManager,
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "label": _vm.$t('startupFeatureManagedBy')
    },
    model: {
      value: _vm.brand.features.startup.managedBy,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features.startup, "managedBy", $$v);
      },
      expression: "brand.features.startup.managedBy"
    }
  })], 1)], 1)], 1), _vm.brandManagedStartups.length > 0 ? _c('v-divider') : _vm._e(), _vm.brandManagedStartups.length > 0 ? _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', [_c('p', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('ManagedStartups')) + " ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headersManagedStartups,
      "items": _vm.brandManagedStartups,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function ($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandGeneral',
              params: {
                brandUUID: `${item._id}`
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: `item.created`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandGeneral',
              params: {
                brandUUID: `${item._id}`
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long")) + " ")])];
      }
    }, {
      key: `item.action`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'BrandGeneral',
              params: {
                brandUUID: `${item._id}`
              }
            }
          }
        }, [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "to": {
              name: 'BrandGeneral',
              params: {
                brandUUID: `${item._id}`
              }
            },
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.brand ? _c('v-card', {
    staticClass: "my-5"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('sharingFeature')))]), _c('v-card-text', {
    staticClass: "mx-3"
  }, [_c('v-row', [_c('p', [_vm._v(_vm._s(_vm.$t('sharingFeatureDescription1')))])]), _c('v-row', [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('sharingFeatureEnabled')
    },
    model: {
      value: _vm.brand.features.sharing.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features.sharing, "enabled", $$v);
      },
      expression: "brand.features.sharing.enabled"
    }
  })], 1), _c('v-row', [_c('v-form', {
    ref: "sharingFeatures",
    staticClass: "col-11"
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "_id",
      "multiple": "",
      "outlined": "",
      "chips": "",
      "label": _vm.$t('canShareWith'),
      "disabled": !_vm.brand.features.sharing.enabled
    },
    model: {
      value: _vm.brand.features.sharing.sharingWith,
      callback: function ($$v) {
        _vm.$set(_vm.brand.features.sharing, "sharingWith", $$v);
      },
      expression: "brand.features.sharing.sharingWith"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.brand ? _c('v-card', {
    staticClass: "my-5"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('PrivateSuppliersList')))]), _c('v-card-text', {}, [_c('v-row', [_c('v-col', [_c('p', [_vm._v(_vm._s(_vm.$t('PrivateSuppliersListDescription1')))]), _c('p', [_vm._v(_vm._s(_vm.$t('PrivateSuppliersListDescription2')))])])], 1), _c('v-row', [_c('v-col', [_c('v-form', {
    ref: "privateSuppliersFeatureRef"
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.suppliers,
      "item-text": "name",
      "item-value": "_id",
      "multiple": "",
      "outlined": "",
      "chips": "",
      "label": _vm.$t('PrivateSuppliers'),
      "menu-props": {
        bottom: true
      },
      "clearable": "",
      "auto": ""
    },
    on: {
      "input": _vm.selectedPrivateSuppliersChanged
    },
    model: {
      value: _vm.selectedPrivateSuppliers,
      callback: function ($$v) {
        _vm.selectedPrivateSuppliers = $$v;
      },
      expression: "selectedPrivateSuppliers"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersPrivateSuppliers,
      "items": _vm.brand.features.privateSuppliers
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref4) {
        var _vm$suppliersMap$item;
        var item = _ref4.item;
        return [_c('span', [_vm._v(" " + _vm._s((_vm$suppliersMap$item = _vm.suppliersMap[item.supplier]) === null || _vm$suppliersMap$item === void 0 ? void 0 : _vm$suppliersMap$item.name) + " ")])];
      }
    }, {
      key: `item.billing`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-simple-checkbox', {
          attrs: {
            "ripple": false
          },
          model: {
            value: item.billing,
            callback: function ($$v) {
              _vm.$set(item, "billing", $$v);
            },
            expression: "item.billing"
          }
        })];
      }
    }], null, true)
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.getBrand
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function ($event) {
        _vm.brand.features.userQuota >= _vm.brandRoles.length ? _vm.modifyBrandFeatures() : _vm.usersSortingDialog = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1), _c('v-card-actions')], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "width": "500",
      "persistent": ""
    },
    model: {
      value: _vm.usersSortingDialog,
      callback: function ($$v) {
        _vm.usersSortingDialog = $$v;
      },
      expression: "usersSortingDialog"
    }
  }, [_c('v-card', [_c('v-card-title'), _c('v-card-text', [_c('v-alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('usersSortingDialogTitle')) + " ")]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('usersSortingDialogText'))
    }
  }), _vm._l(_vm.brandRoles, function (user) {
    return _c('v-checkbox', {
      key: user.email,
      attrs: {
        "label": `${user.email}
        ${user.firstname !== ' ' || user.lastname !== ' ' ? `(${user.firstname} ${user.lastname})` : ''}`,
        "value": user.email,
        "color": "info"
      },
      model: {
        value: _vm.usersToDelete,
        callback: function ($$v) {
          _vm.usersToDelete = $$v;
        },
        expression: "usersToDelete"
      }
    });
  })], 2), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.usersToDelete = [];
        _vm.getBrand();
        _vm.usersSortingDialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")]), _vm.usersToDelete.length > 0 && _vm.usersToDelete.length >= _vm.brandRoles.length - _vm.brand.features.userQuota ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.deleteBrandUsers();
        _vm.usersSortingDialog = false;
      }
    }
  }, [_vm._v(" delete " + _vm._s(_vm.usersToDelete.length) + " users ")]) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }